import { Inject, injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import type Day from '@/modules/common/types/day.type';

export const FleetHistoryApiServiceS = Symbol.for('FleetHistoryApiServiceS');
@injectable(FleetHistoryApiServiceS as unknown as string)
export default class FleetHistoryApiService {
    @Inject(ApiServiceS) private apiService!: ApiService;
}
