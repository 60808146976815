
import ChainService, { ChainServiceS } from '@/modules/chain/chain.service';
import { Component, Mixins } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ChainGroup from '@/modules/chain/interfaces/chain-group.enum';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import RatesClusterTable from '@/modules/cluster/components/rates/table/rates-cluster-table.vue';
import RatesClusterActions from '@/modules/cluster/components/rates/rates-cluster-actions.vue';
import RatesClusterHeader from '@/modules/cluster/components/rates/rates-cluster-header.vue';
import ClusterOtelLogsMixin from '@/modules/open-telemetry/mixins/rates/rates-cluster-otel.mixin';
import Pagination from '@/modules/common/components/ui-kit/pagination.vue';
import RatesFiltersService, { RatesFiltersServiceS } from '../../rates/rates-filters.service';

@Component({
    components: {
        PageWrapper,
        RatesClusterHeader,
        RatesClusterActions,
        RatesClusterTable,
        Pagination,
    },
})
export default class RatesClusterTablePage extends Mixins(ClusterOtelLogsMixin) {
    @Inject(RatesFiltersServiceS) private ratesFiltersService!: RatesFiltersService;
    @Inject(ChainServiceS) private chainService!: ChainService;

    beforeMount() {
        const { params } = this.$route;
        const { group, value } = params as {
            group: ChainGroup;
            value: string;
        };

        this.chainService.resetChainPair();

        if (group && value) {
            this.chainService.setChainPair(group, value);
        }

        this.ratesFiltersService.resetProvider();
        this.clusterService.hotels = null;
    }

    destroyed() {
        this.clusterService.resetLoading();
    }
}
