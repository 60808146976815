
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import IPriceData from '@/modules/cluster/interfaces/price-data.interface';
import PriceFilter from '@/modules/common/filters/price.filter';
import PercentFilter from '@/modules/common/filters/percent.filter';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import { FleetHistoryApiServiceS } from '@/modules/cars/modules/cars-price-history/fleet-history-api.service';
import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';

@Component({
    filters: { PercentFilter, PriceFilter },
})
export default class PriceData extends Vue {
    @Inject(HelperServiceS) helperService!: HelperService;
    @Inject(EventsManagerServiceS) eventsManagerService!: EventsManagerService;
    @Inject(ClusterRatesServiceS) clusterRatesService!: ClusterRatesService;
    @Inject(ClusterServiceS) private clusterService!: ClusterService;

    @Prop({ type: Object, required: true })
    priceData!: IPriceData | null;

    @Prop({ type: Boolean, required: true })
    hasBorder!: boolean;

    @Prop({ type: Date, required: false })
    date!: Date;

    @Prop({ type: Number })
    cellIndex!: number;

    get isHaveHolidayEvents() {
        if (!this.date) return FleetHistoryApiServiceS;
        return this.eventsManagerService.hasDateHolidayEvents(this.date);
    }

    get isHaveLocalEvents() {
        if (!this.date) return false;
        return this.eventsManagerService.hasDateOtherEvents(this.date);
    }

    get isLoading() {
        const data = this.clusterService.getHotelData(this.priceData?.hotelId!);
        return !data || !data?.documentLoaded;
    }

    get isFirstCell() {
        return this.date && this.date.getDate() === 1;
    }

    onHover() {
        if (!this.priceData) return;

        const { hotelId } = this.priceData!;
        const { priceCell: componentContainer } = this.$refs;
        const { cellIndex } = this;
        this.$emit('hover', { cellIndex, hotelId, componentContainer });
    }

    onOut() {
        this.$emit('hover', false);
    }
}
